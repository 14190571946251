/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 *  This component translates navigation menu content from WCS Composer or CMS/Graphql
 *  into a simplified menu object hierarchy used for input to either mobile hamburger menu or horizontal desktop menu.
 *
 *  target = 'Mobile' or 'Desktop'
 *       'Mobile' - after translating input, <NavMenu> is invoked to present mobile hamburger menu
 *       'Desktop' - after translating input, <DesktopNavMenu> is invoked to present horizontal desktop menu.
 *
 *  featureFlags['is-graphql-enabled'] = true or false
 *        true - input is a graphql object hierarchy originating from CMS
 *        false - input is a flat array originating from composer (see buildNavMenuFromComposer below)
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, { bool, string } from 'prop-types';
import mbpLogger from 'mbp-logger';

import { withStyles } from '@material-ui/core/styles';

import loadable from '@loadable/component';
import NavMenu from './NavMenu/NavMenu';
import DesktopNavMenu from '../../../../GraphqlComponents/GraphqlHeader/TopMenu/DesktopNavMenu/DesktopNavMenu';
import { getPresentationFamily, getFeatureFlags } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import replaceLinkTokens from '../../../../../helpers/contentstack/replaceLinkTokens';
import LoginDropdown from './SlideNavMenu/Items/LoginDropdown';

const SlideNav = loadable(() => import(/* webpackChunkName: "SlideNav" */ './SlideNavV2/SlideNav'));
const SlideNavMenu = loadable(() => import(/* webpackChunkName: "SlideNavMenu" */ './SlideNavMenu/SlideNavMenu'));

const TARGET_DESKTOP = 'Desktop';
const TARGET_MOBILE = 'Mobile';

// Supported by Composer and CMS/graphql
const LINKSTYLE_NORMAL = 'Normal';
const LINKSTYLE_ALTERNATE = 'Alternate';
const LINKSTYLE_DIVIDER = 'Divider';
// Supported by CMS/graphql only
const LINKSTYLE_HEADING = 'Heading';

const styles = () => ({
    divider: {
        height: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'vertical',
    },
});

// translate composer or cms/graphql menu spec, then call <NavMenu> (mobile) or <DesktopNavMenu> component to present menu
class MenuBuilder extends Component {
    //
    // methods that build navmenu object hierarchy from composer input
    //

  /*
   *  buildNavMenuFromComposer --
   *
   *  input = array of objects: {label: 'composer-formatted label' , link: 'relative or absolute url'}
   *  output = menu object hierarchy suitable for input to NavMenu (mobile LeftMenu)
   *
   *  item's label contains the following magic characters to do formatting:
   *  label = '[[icon-name]] description'  -->  display given icon for menu item
   *  label = '~ description'  --> display menu item with alternate styling (e.g. inverse for H&D Account Links)
   *  (if icon and alternate formatting both desired, specify as '~[[icon-name]] description')
   *  label = '<<DIVIDER>>' --> display a gap between sections
   *  label = '> description'  --> item is a sub-menu item for prior top-level item (i.e. item that doesn't have >)
   *  label = '>> description'  --> item is a sub-menu item for prior item that begins >
   *  label = '>>> description'  --> item is a sub-menu item for prior item that begins >>
   *  supports any number of sub'ing with additional >'s
   *
   * Label examples:
   *   TopLevel Item "Summer Pleasures"
   *   Sub-Item "> Sun"
   *   Sub-Item "> Sand"
   *   Sub-Item "> Ice Cream"
   *   Sub-Sub-Item ">> Cones"
   *   Sub-Sub-Item ">> Sundaes"
   *
   * Example item:
   *  {label: "[[person]] My Account", link: "/AjaxLogonForm?MyAccount=Y&catalogId=11102&langId=-1&storeId=10202" }
   */
  buildNavMenuFromComposer = (menuArray, level, parentPosition) => {
      const newMenu = [];
      while (MenuBuilder.menuIndex < menuArray?.length) {
      // if there are menu items left to process...
          const currentItem = { ...menuArray[MenuBuilder.menuIndex] }; // copy .label and .link

          // level = the number of leading >>>'s at beginning of label
          let newLevel = 0;
          const m = currentItem?.label?.match(/^(>+)\s*(\S.*\S)\s*$/);
          if (m) {
              newLevel = m[1]?.length;
              [, , currentItem.label] = m; // label with >>>'s removed
          }

          if (newLevel === level) {
              // at same level.  Just add this item.
              currentItem.level = level; // used for component key
              currentItem.parentPosition = parentPosition; // parent's index; used for panel expansion test

              // Use alternate styling if label begins with ~  (e.g. for H&D Account Links)
              if (currentItem?.label?.substring(0, 1) === '~') {
                  currentItem.linkStyle = LINKSTYLE_ALTERNATE;
                  currentItem.label = currentItem?.label?.substring(1)?.trim(); // remove leading ~
              } else if (currentItem?.label?.match('<<DIVIDER>>')) {
                  currentItem.linkStyle = LINKSTYLE_DIVIDER;
              } else {
                  currentItem.linkStyle = LINKSTYLE_NORMAL;
              }

              const m2 = currentItem?.label?.match(/^(\[\[\S+\]\])\s+(\S.*\S)\s*$/); // e.g.  '[[location-icon-white]] Find a Store'
              if (m2) {
                  [, currentItem.icon, currentItem.label] = m2;
              }

              newMenu.push(currentItem);
          } else if (newLevel > level) {
              // Going deeper.  Recursive call to add submenu.
              // Last item in newMenu is submenu's parent
              const parentIndex = newMenu?.length - 1;
              if (parentIndex >= 0) {
                  newMenu[parentIndex].submenu = this.buildNavMenuFromComposer(
                      menuArray,
                      level + 1,
                      parentIndex,
                  );
              }
          } else {
              // Going up.  We're done with current submenu.
              // Put this item back so caller will grab it.
              MenuBuilder.menuIndex -= 1;
              return newMenu;
          }

          MenuBuilder.menuIndex += 1;
      }
      return newMenu;
  };

  //
  // methods that build navmenu object hierarchy from cms/graphql input
  //

    // translate a CMS/Graphql item object into NavMenuItem object
    buildMenuItemFromGraphqlItem = (item, level, parentPosition = -1) => {
        const { target, brand } = this.props;

        const navMenuItem = {
            level,
            parentPosition,
            label: '',
            link: '',
            trackingCategory: '',
            trackingLabel: '',
            trackingAction: '',
            imageUrl: '',
            textField: '',
            colorField: '',
            menu_style: '',
            categoryOffer: '',
        };

        if (item) {
            if (item.is_heading || item.sub_menu) {            // TODO: sub_menu obsolete.  Remove when gone from CMS
                navMenuItem.linkStyle = LINKSTYLE_HEADING;
            } else {
                navMenuItem.linkStyle = item.link_style || LINKSTYLE_NORMAL;
            }

            if (item.link) {
                if (item.link.title) {
                    navMenuItem.label = item.link.title;
                    if (item.text_field) {
                        navMenuItem.textField = item.text_field;
                        navMenuItem.colorField = item.color_field.color;
                    }
                }
                if (item.link.href)  navMenuItem.link = replaceLinkTokens(item.link.href, brand);
            }
            if (item.font_color) navMenuItem.color = item.font_color;
            if (item.background_color) navMenuItem.backgroundColor = item.background_color;
            if (item.additional_height) navMenuItem.additionalHeight = item.additional_height;

            if (target === TARGET_DESKTOP) {
                navMenuItem.column = item.column || 1;
            }
            navMenuItem.trackingCategory = item.tracking_event_category || '';
            navMenuItem.trackingAction = item.tracking_event_action || '';
            navMenuItem.trackingLabel = item.tracking_event_label || '';
            navMenuItem.icon = item.icon || null;
            navMenuItem.is_vertical_menu_title = item.is_vertical_menu_title || false;
            navMenuItem.pim_collection_code = item.pim_collection_code || '';
            navMenuItem.menu_sub_heading = item.menu_sub_heading || '';
            navMenuItem.discount_text = item.discount_text || '';
            if (item.image?.url) {
                navMenuItem.imageUrl = item.image?.url || null;
            }
            navMenuItem.hide_on_non_clickable_collection = item?.hide_on_non_clickable_collection || false;
        }
        return navMenuItem;
    };

  // empty top-level item with subs
  buildMenuItemFromGraphqlGroup = (menuGroup) => ({     // eslint-disable-line arrow-parens
      level: 0,
      parentPosition: -1,
      label: menuGroup.title,
      link: menuGroup?.link?.href || '',
      icon: null,
      linkStyle: LINKSTYLE_NORMAL,
      menu_image: menuGroup?.menu_image,
      main_link: menuGroup?.main_link,
      holiday_banner_images: menuGroup?.holiday_banner_images || [],
      style: menuGroup?.style || [],
      columnWidths: menuGroup?.column_width || '',
      fullWidthSubmenu: menuGroup?.full_width_submenu,
      categoryOffer: menuGroup?.category_offers || '',
      track_event: {
          tracking_event_category: menuGroup?.tracking_event_category || '',
          tracking_event_action: menuGroup?.tracking_event_action || '',
          tracking_event_label: menuGroup?.tracking_event_label || '',
      },
      menu_style: menuGroup?.menu_style,
      menu_header_image: menuGroup?.image,
      hide_on_non_clickable_collection: menuGroup?.hide_on_non_clickable_collection,
  });

  // CMS/Graphql only --
  // Include this thing (menu block, menu item, etc.) for our current target (desktop/mobile)?
  // (e.g. if target is "Desktop", return false if thing is Mobile-only)
  // Returns True if thing has no viewports specified(i.e. valid for all) or thing's viewport contains the given target
    isThingValidForTarget = (thing, target) => {
        if (thing) {
            return !thing.viewport
            || !Array.isArray(thing.viewport)
            || thing.viewport?.length === 0
            || !thing.viewport?.[0]?.select
            || !Array.isArray(thing.viewport?.[0]?.select)
            || thing.viewport?.[0]?.select?.includes(target);
        }
        return null;
    };

    // translate list of CMS/Graphql group sub items into NavMenuItem objects for Level 3
    buildMenuListFromGraphqlList = (menuList, level, parentIndex) => {
        const { target } = this.props;
        const newSubmenu = [];
        menuList.forEach((menuItem) => {
            if (this.isThingValidForTarget(menuItem, target)) {
                newSubmenu.push(this.buildMenuItemFromGraphqlItem(menuItem, level, parentIndex));
            }
        });
        return newSubmenu;
    };

    // translate list of CMS/Graphql group sub items into NavMenuItem objects for Level 2
    buildMenuGroupListFromGraphqlList = (menuList, level, parentIndex) => {
        const { target } = this.props;
        // currently nothing below level 1 supported
        const newSubmenu = [];
        menuList.forEach((menuItem) => {
            if (this.isThingValidForTarget(menuItem, target)) {
                const oKind = Object.keys(menuItem)[0]; // block's type
                switch (oKind) {
                    case 'menu_item':
                        // top-level menu item
                        newSubmenu.push(this.buildMenuItemFromGraphqlItem(menuItem[oKind], level, parentIndex));
                        break;

                    case 'menu_groups': {
                        // top-level item followed by list of its sub items
                        newSubmenu.push(this.buildMenuItemFromGraphqlGroup(menuItem[oKind]));
                        const parentSubIndex = newSubmenu?.length - 1;

                        if (menuItem[oKind]?.menu_item && Array.isArray(menuItem[oKind]?.menu_item)) {
                            newSubmenu[parentSubIndex].submenu = this.buildMenuListFromGraphqlList(
                                menuItem[oKind]?.menu_item,
                                1,
                                parentSubIndex,
                            );
                        }
                        break;
                    }

                    default:
                        mbpLogger.logWarning({
                            function: 'MenuBuilder',
                            appName: process.env.npm_package_name,
                            module: 'mbp-header',
                            message: `Unrecognized menuBlock "${oKind}"`,
                        });
                }
            }
        });
        return newSubmenu;
    };

  // Translates cmsgraphql blocks into navmenu object hierarchy
  // (see LeftMenu component for specifics)
  buildNavMenuFromGraphqlBlocks = (blocks) => {
      const { target } = this.props;
      const newMenu = [];

      blocks.forEach((block) => {
      // menuBlock is an object with only one key:attribute.  Its key string identifies type of block.  Its attribute is object containing block's data.
          const oKind = Object.keys(block)[0]; // block's type
          const o = block[oKind]; // block's data
          if (o && this.isThingValidForTarget(o, target)) {
              switch (oKind) {
                  case 'widgets': {
                      // e.g. SEARCH
                      const currentItem = this.buildMenuItemFromGraphqlItem(o, 0);
                      currentItem.type = o.type;
                      currentItem.label = o.type;
                      newMenu.push(currentItem);
                      break;
                  }

                  case 'menu_links': // one or more top-level menu items
                      if (o.menu_link) {
                          o.menu_link.forEach((menuItem) => {
                              if (this.isThingValidForTarget(menuItem, target)) {
                                  newMenu.push(this.buildMenuItemFromGraphqlItem(menuItem, 0));
                              }
                          });
                      }
                      break;

                  case 'menu_groups': {
                      // top-level item followed by list of its sub items
                      newMenu.push(this.buildMenuItemFromGraphqlGroup(o));
                      const parentIndex = newMenu?.length - 1;

                      if (o.menu_blocks && Array.isArray(o.menu_blocks) && o.menu_blocks?.length > 0) {
                          newMenu[parentIndex].submenu = this.buildMenuGroupListFromGraphqlList(
                              o.menu_blocks,
                              1,
                              parentIndex,
                          );
                      }
                      if (o.menu_item && Array.isArray(o.menu_item) && o.menu_item?.length > 0) {
                          newMenu[parentIndex].submenu = this.buildMenuListFromGraphqlList(
                              o.menu_item,
                              1,
                              parentIndex,
                          );
                      }
                      if (o.menu_blocks && o.menu_item && Array.isArray(o.menu_blocks) && Array.isArray(o.menu_item) && o.menu_blocks?.length === 0 && o.menu_item?.length === 0) {
                          newMenu[parentIndex].link = o.main_link.href;
                      }
                      break;
                  }

                  default:
                      mbpLogger.logWarning({
                          function: 'MenuBuilder',
                          appName: process.env.npm_package_name,
                          module: 'mbp-header',
                          message: `Unrecognized menuBlock "${oKind}"`,
                      });
              }
          }
      });

      return newMenu;
  };

  render() {
      const {
          isBot, menu, handleClose, brand, attributes, featureFlags, target, presentation_family, isMobileMenuV2Enabled, guidedNav, keeperData,
      } = this.props;

      if (
          !MenuBuilder.cachedMenu
      || target === TARGET_DESKTOP  // TODO: cache by menu id for desktops (desktop may have several menus)
      || MenuBuilder.cachedMenuTarget !== target
      || MenuBuilder.cachedBrand !== brand
      ) {
          MenuBuilder.menuIndex = 0;

          MenuBuilder.cachedMenu = this.buildNavMenuFromGraphqlBlocks(menu, 0, 0); // !!!! for V2 will need to prefetch content !!!!
          MenuBuilder.cachedMenuTarget = target;
          MenuBuilder.cachedBrand = brand;
      }

      if (target === TARGET_DESKTOP) {
          return (<DesktopNavMenu navMenuList={MenuBuilder.cachedMenu} presentation_family={presentation_family} brand={brand} attributes={attributes} />);
      }

      if (isMobileMenuV2Enabled) {
          return (
              <SlideNav
                  isBot={isBot}
                  navMenuList={MenuBuilder.cachedMenu}
                  handleClose={handleClose}
                  brand={brand}
                  data={menu}
                  guidedNav={guidedNav}
                  keeperData={keeperData}
              />
          );
      }
      if (featureFlags && featureFlags['mobile-submenu-presentation'] === 'slide-replace') {
          return (
              <>
                  <SlideNavMenu
                      isBot={isBot}
                      navMenuList={MenuBuilder.cachedMenu}
                      handleClose={handleClose}
                      brand={brand}
                      data={menu}
                      keeperData={keeperData}
                  />
              </>
          );
      }
      return (
          <>
              <LoginDropdown handleClose={handleClose} />
              <NavMenu
                  isBot={isBot}
                  navMenuList={MenuBuilder.cachedMenu}
                  handleClose={handleClose}
                  brand={brand}
              />
          </>
      );
  }
}

MenuBuilder.propTypes = {
    menu: PropTypes.array.isRequired,
    handleClose: PropTypes.func,
    brand: PropTypes.object,
    attributes: PropTypes.object,
    featureFlags: PropTypes.object.isRequired,
    target: PropTypes.string,
    isBot: PropTypes.bool,
    presentation_family: string.isRequired,
    isMobileMenuV2Enabled: bool,
    guidedNav: PropTypes.array,
    keeperData: PropTypes.object,
};

MenuBuilder.defaultProps = {
    brand: {},
    attributes: {},
    target: TARGET_MOBILE,
    handleClose: null, // func?
    isBot: false,
    isMobileMenuV2Enabled: false,
    guidedNav: [],
    keeperData: {},
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    presentation_family: getPresentationFamily(state),
});

export default withStyles(styles)(
    connect(
        mapStateToProps,
        null,
    )(MenuBuilder),
);
